
span.ag-header-cell-text {
    white-space: normal;
    /*text-overflow: ;*/
}

.ag-theme-alpine .ag-row-odd {
    background-color: #e8e8ff;
}

.ag-header-cell-label {
    white-space: normal !important;
}


.custom-text-center {
    justify-content: center;
}


.ag-theme-alpine .ag-header-group-cell-label {
    /* flex-direction: row-reverse; */
    justify-content: center;
}